import React, { useCallback, useMemo } from 'react'
import { moneyFormatter } from 'src/client/common/formatters/money-formatter'
import { percentFormatter } from 'src/client/common/formatters/percent-formatter'
import { translate } from 'src/client/common/translatable/translatable'

import { usePlanModal } from '../../hooks/usePlanModal'
import { Emoji } from '../emoji/emoji.component'

import { DiscountBarProps } from './@types/discount-bar.types'
import styles from './discount-bar.module.scss'

const DiscountBar: React.FC<DiscountBarProps> = ({ discountData }) => {
  if (!discountData) {
    return null
  }

  const { discountPercentage, discountAmount, discountCodeEntity } = discountData

  if (!discountPercentage && !discountAmount) {
    return null
  }

  const { toggleIsPlanModalOpen } = usePlanModal()
  const handleButtonClick = () => toggleIsPlanModalOpen()

  const discountValue = useMemo(() => {
    if (discountPercentage > 0) {
      return `-${percentFormatter(discountPercentage)}`
    }

    if (discountAmount > 0) {
      return `-${moneyFormatter(discountAmount)}`
    }

    return ''
  }, [discountPercentage, discountAmount])

  const DiscountMessage: React.FC = useCallback(() => {
    const { deliveriesNumber } = discountCodeEntity

    const isFirstOrder = !discountCodeEntity ||
    !discountCodeEntity.deliveriesNumber ||
    discountCodeEntity.deliveriesNumber === 1;

    const orderNowButton = (<button onClick={handleButtonClick} className={styles.discountButton}>
      {translate('ODBIERZ TERAZ!')}
    </button>)

    if (isFirstOrder) {
      return (
        <>
          {`
        ${translate('Otrzymujesz')} 
        ${discountValue}
        ${translate('zniżki na pierwsze zamówienie.')}`}
          {orderNowButton}
        </>
      )
    }

    if (deliveriesNumber >= 2) {
      return (
        <>
          {`
        ${translate('Otrzymujesz')} 
        ${discountValue}
        ${translate('zniżki na')} ${deliveriesNumber} ${translate('pierwsze zamówienia.')}`}
          {orderNowButton}
        </>
      )
    }

    return <></>;
  }, [discountCodeEntity])

  return (
    <div className="text-center text-white p-2 bg-green mobile-fs-14 mobile-extrasmall-fs-12">
      <span className="d-none d-md-inline-block mr-2">
        <span className="d-none d-md-inline-block mr-2">
          <Emoji label="party popper" symbol="🎉" className={styles.tada} />
          <DiscountMessage />
        </span>
      </span>
    </div>
  )
}

export default DiscountBar
