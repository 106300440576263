import { DiscountTypeEnum } from 'piesotto-shared/discount-code-dtos/discount-type.enum'
import React, { useEffect } from 'react'

import { useIsDiscountBar } from '../../hooks/useIsDiscountBar'
import { CookieStorage } from '../../modules/plan/plan-storage/@enum/cookie-storage.enum'
import { DiscountData } from '../@types/discount-data'
import DiscountBar from '../discount-bar/discount-bar.component'

export interface DiscountBarProps {
  discountData: DiscountData
}

const defaultDiscount = {
  codeName: 'PIESOTTO2x15',
  codePercentage: 15,
  deliveriesNumber: 2
}

const shouldDisplayDefaultDiscount = false

const temporaryDiscountData = {
  codeName: 'FITPIESTBX',
  codePercentage: 15,
  deliveriesNumber: 2,
  discountAmount: 0
}

const discountData: DiscountData = {
  isValid: true,
  discountAmount: !shouldDisplayDefaultDiscount ? temporaryDiscountData?.discountAmount : 0,
  discountPercentage: shouldDisplayDefaultDiscount
    ? defaultDiscount?.codePercentage
    : temporaryDiscountData?.codePercentage,
  discountCodeEntity: {
    code: shouldDisplayDefaultDiscount
      ? defaultDiscount?.codeName
      : temporaryDiscountData?.codeName,
    deliveriesNumber: shouldDisplayDefaultDiscount
      ? defaultDiscount?.deliveriesNumber
      : temporaryDiscountData?.deliveriesNumber,
    discountAmount: !shouldDisplayDefaultDiscount ? temporaryDiscountData?.discountAmount : 0,
    discountPercentage: shouldDisplayDefaultDiscount
      ? defaultDiscount?.codePercentage
      : temporaryDiscountData?.codePercentage,
    discountProps: null,
    id: '',
    isUsed: false,
    owner: null,
    type: DiscountTypeEnum.Amount,
    validFrom: '',
    validUntil: ''
  }
}

const DiscountBarMainPage: React.FC = () => {
  const { setIsDiscountBarTrue } = useIsDiscountBar()

  useEffect(() => {
    setIsDiscountBarTrue()
  }, [])

  if (!discountData) {
    return null
  }

  if (typeof window === 'object') {
    document.cookie = `${CookieStorage.DISCOUNT_CODE}=${discountData.discountCodeEntity.code};path=/`
    document.cookie = `${CookieStorage.DISCOUNT}=${JSON.stringify(discountData)};path=/`
  }

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 1000,
        width: '100%'
      }}
    >
      <DiscountBar discountData={discountData} />
    </div>
  )
}

export default DiscountBarMainPage
